import { I18n } from 'react-redux-i18n';
import { take, put, call, all, select } from 'redux-saga/effects';
import { notify } from '../../../../../Components/Notifier/services/saga';

import {
	initAndGenerateExport,
	initApiData,
	initEntities,
	patchPartnerFloor,
	patchPartnerPaiementType,
	patchPartnerPriceCategory,
	preGenerateLinkPartnerToCustomer,
	setActiveAuthLink,
	setApiMask,
	setOptionPartner,
	setPartnerMedDefaut,
	watchClickAndCollectLink,
} from '../saga';
import { PRE_CHANGE_PARTNER_STATUS, PRE_CHANGE_PARTNER_STEP } from '../types';
import { handlePatchVariable } from '../../../../VariableServer/service/saga';
import { activeStockManagement } from '../../../Components/ProductQuantity/service/saga';
import { refreshBindings } from '../../../../../service/fetcher.saga';

const PARTNER_PREFIX = 'deliveroo';

function* config() {
	while (true) {
		const { data } = yield take('PUT_CONFIG_DELIVEROO');
		let success = false;
		let errorMessage = null;
		let currentStep = data.step;

		let bodySetStep = {
			id_user: data.userId,
			partner_id: data.partnerId,
			step: data.nextStep,
			status: data.status,
		};

		if (currentStep === 'initial') {
			yield call(setPartnerMedDefaut, {
				partnerPrefix: data.prefix,
				partnerId: data.partnerId,
			});
			yield put({
				type: 'PRE_PATCH_PARTNER_EXTERNAL_ID',
				body: { prefix: PARTNER_PREFIX, external_id: bodySetStep.id_user },
			});
			yield take('PATCH_PARTNER');

			currentStep = 'set_api_data';
			bodySetStep.step = 'set_api_data';
			success = true;
		}
		if (currentStep === 'set_api_data') {
			const successInitApiData = yield call(initApiData, PARTNER_PREFIX);
			if (successInitApiData) {
				success = true;
				bodySetStep.step = 'documentation';
			}
		}

		if (currentStep === 'documentation') {
			success = true;
		}

		if (currentStep === 'set_brand_id') {
			yield put({
				type: 'PRE_REQUEST_POST_PARTNER_CHECKING',
				body: {
					partnerPrefix: PARTNER_PREFIX,
					id: data.userId,
					uuid: `${data.userId}`,
				},
			});
			const { result } = yield take('REQUEST_POST_PARTNER_CHECKING_FINISHED');

			if (
				result &&
				result.status === 'success' &&
				result.responseData &&
				result.responseData.brand_id &&
				result.responseData.brand_id.length
			) {
				yield call(handlePatchVariable, {
					key: 'deliverooBrand',
					value: result.responseData.brand_id[0],
				});

				success = true;
				currentStep = 'set_auth_link';
				bodySetStep.step = 'set_auth_link';
			} else {
				errorMessage = 'partner.onboarding_deliveroo_errors.set_brand_id';
			}
		}

		if (currentStep === 'set_auth_link') {
			let res = yield call(preGenerateLinkPartnerToCustomer, {
				partnerId: data.partnerId,
				uuid: bodySetStep.id_user,
			});

			if (res && res.success) {
				yield call(
					setActiveAuthLink,
					false,
					bodySetStep.id_user,
					data.partnerId
				);
				bodySetStep.step = 'set_entities';
				success = true;
			}
		}

		if (currentStep === 'set_entities') {
			const { binding } = yield select();
			let paiementType = binding.paymentType.find(f => f.id === 'deliveroo');
			let table = binding.floor.find(f => f.id === 'deliveroo-1');
			let priceCategory = binding.paymentType.find(f => f.id === 'deliveroo');

			let needInsert = !paiementType || !table || !priceCategory;
			let insertSuccess = false;
			if (needInsert) {
				insertSuccess = yield call(initEntities, PARTNER_PREFIX);
			}

			if (!needInsert || insertSuccess) {
				let resultPaiementType;
				let resultFloor;
				let resultPriceCategory;
				if (needInsert) {
					yield call(refreshBindings); // refresh des tables et moyens de paiement dans le store redux

					let body = {
						prefix: PARTNER_PREFIX,
						id_payment_type: 'deliveroo',
					};

					resultPaiementType = yield call(patchPartnerPaiementType, body);

					body = {
						prefix: PARTNER_PREFIX,
						id_table: 'deliveroo-1',
					};

					resultFloor = yield call(patchPartnerFloor, body);

					body = {
						prefix: PARTNER_PREFIX,
						id_price_category: 'deliveroo',
					};

					resultPriceCategory = yield call(patchPartnerPriceCategory, body);
				}

				if (
					!needInsert ||
					(resultPaiementType &&
						resultPaiementType.result &&
						resultFloor &&
						resultFloor.result &&
						resultPriceCategory &&
						resultPriceCategory.result)
				) {
					success = true;
					currentStep = 'set_partner_options';
					bodySetStep.step = 'set_partner_options';
				} else {
					errorMessage = 'partner.onboarding_common_errors.' + currentStep;
				}
			} else {
				errorMessage = 'partner.onboarding_common_errors.' + currentStep;
			}
		}

		if (currentStep === 'set_partner_options') {
			const resultInitOption = yield call(setOptionPartner, PARTNER_PREFIX);
			if (resultInitOption) {
				currentStep = 'set_api_mask';
				bodySetStep.step = 'set_api_mask';
				success = true;
			}
		}

		if (currentStep === 'set_api_mask') {
			bodySetStep.status = 'actif';
			const setApiMaskSuccess = yield call(setApiMask, {
				...bodySetStep,
				prefix: PARTNER_PREFIX,
			});

			if (setApiMaskSuccess) {
				success = true;
				bodySetStep.step = 'success';
				bodySetStep.status = 'inactif';
			} else {
				bodySetStep.status = 'configuration';
			}
		}
		if (success) {
			yield put({
				type: PRE_CHANGE_PARTNER_STEP,
				body: bodySetStep,
			});
			yield put({
				type: PRE_CHANGE_PARTNER_STATUS,
				body: bodySetStep,
			});
		}

		if (errorMessage) {
			yield call(notify, I18n.t(errorMessage), 'error', 5000);
		}

		yield put({
			type: 'PUT_CONFIG_DELIVEROO_FINISHED',
			step: bodySetStep.step,
		});
	}
}

export default function* indexSaga() {
	yield all([config()]);
}
