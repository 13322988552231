import React, { Component } from 'react';
import { I18n } from 'react-redux-i18n';
import CardConfig from '../index';
import Button from '../../../Button';
import Expand from 'react-expand-animated';
import { connect } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import {
	//putPartnerStep,
	putPartnerStatus,
	generateToken,
	validLinkPartnerToCustomer,
	validConnexionWithPartnerOk,
	postPartnerMedDefault,
	postPartnerChecking,
	postInitializationPartner,
	getCheckMenu,
	patchPartnerPaiementType,
	patchPartnerFloor,
	patchPartnerPriceCategory,
} from '../../../../Containers/Partners/service/partnersConfig/actions';
import { bindActionCreators } from 'redux';
import { withSnackbar } from 'notistack';
import { getPartnerById } from '../../../../Containers/Partners/selector';
import { Fade } from 'react-slideshow-image';
import { Media, Player } from 'react-media-player';
import { animateScroll as scroll, scroller } from 'react-scroll';
import PaymentType from '../../../../Containers/Partners/Components/Binding/Components/PaymentType';
import Table from '../../../../Containers/Partners/Components/Binding/Components/Table';
import PriceCategory from '../../../../Containers/Partners/Components/Binding/Components/PriceCategory';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { enqueueSnackbar } from '../../../Notifier/services/actions';
import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@material-ui/core';
import {
	compareObjects,
	confirm,
	deepEqual,
	getVariableServer,
	isEmail,
} from '../../../../utils';
import { PUT_CONFIG_PARTNER } from '../../../../Containers/Partners/service/partnersConfig/types';
import { getData } from '../../../../Containers/Partners/service/partnersData/actions';
import {
	ManageAccountingCategoriesLink,
	ManageOutletsLink,
	ManageServicesLink,
	UnderstandServicesLink,
} from '../../../../Containers/Partners/Containers/Mews/documentationLinks';
import PartnerContact from '../../../PartnerContact';
import { getPayplusUrl } from '../../../../service/auth/saga';

// Propriété des slider d'image
const properties = {
	transitionDuration: 500,
	infinite: false,
	indicators: true,
	autoplay: false,
};

const MEWS_STEP_TO_ENTITY = {
	set_service_id: 'services',
	set_outlet_id: 'outlets',
	set_default_payment_id: 'accountCategories',
};

const Accordion = withStyles({
	root: {
		backgroundColor: 'rgba(0, 0, 0, 0.03)',
		boxShadow: 'none',
		'&:not(:last-child)': {
			borderBottom: 0,
		},
		'&:before': {
			display: 'none',
		},
		'&$expanded': {
			margin: 'auto',
		},
	},
	expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
	root: {
		marginBottom: -1,
		minHeight: 56,
		'&$expanded': {
			minHeight: 42,
		},
	},
	content: {
		'&$expanded': {
			margin: '0',
		},
	},
	expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(theme => ({
	root: {
		padding: 0,
		maxHeight: '89px',
		overflow: 'hidden',
		overflowY: 'scroll',
	},
}))(MuiAccordionDetails);

function putPartnerStep(data, prefix) {
	return {
		type: PUT_CONFIG_PARTNER,
		prefix,
		data,
	};
}

class ComplexPartner extends Component {
	constructor(props) {
		super(props);
		this.state = {
			uuid: '',
			partnerOK: false,
			configLaunch: false,
			open: false,
			wrongCorrespondanceId: false,
			newStep: {},
			selectedPaymentType: '',
			selectedTable: '',
			selectedPriceCategory: '',
			selectedMewsData: '',
			expanded: 'panel1',
			redirectWait: false,
			linkClicked: false,
		};
		this.setWrapperRef = this.setWrapperRef.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	// Fonction lors de la reprise d'une configuration en route
	continueConfigure = e => {
		const next = () => {
			this.launchConfig();
			this.sendNewState();
		};

		if (this.props.config.state === 'documentation' && !this.state.doc_readed) {
			this.handleDocUnread(next, this.launchConfig);
		} else {
			next();
		}
	};

	removeListener = () => {
		document.removeEventListener('mousedown', this.handleClickOutside);
	};

	addListener = () => {
		document.addEventListener('mousedown', this.handleClickOutside);
	};

	componentDidUpdate(prevProps, prevState) {
		if (this.props.config.state === 'success') {
			this.props.setBlurBackground(false);
		}

		if (
			prevProps.config.state !== this.props.config.state ||
			prevState.configLaunch !== this.state.configLaunch
		) {
			this.checkExpand();
			this.loadPartnerData();
		}
	}

	handleDocUnread = (onValid, onCancel) => {
		let text = '';
		switch (this.props.config.prefix) {
			case 'ladditionTapToPay':
				text = I18n.t('partner.confirm_didnt_read_doc_yolo.tap_to_pay');
				break;
			default:
				text = I18n.t('partner.confirm_didnt_read_doc_yolo.export');
		}
		confirm(text).then(
			() => {
				onValid();
			},
			() => onCancel
		);
	};

	handleGetMewsData = (step, forceRefresh = false) => {
		if (
			(forceRefresh || !this.props.partnersData.mewsSystems) &&
			!['initial', 'documentation', 'set_customer_id'].includes(step)
		) {
			this.props.getPartnerData('mewsSystems');
		}
	};

	handleGetTickncookData = (step, forceRefresh = false) => {
		if (
			(forceRefresh || !this.props.partnersData.tickncook) &&
			step === 'set_tickncook_kitchen_id'
		) {
			this.props.getPartnerData('tickncook');
		}
	};

	handleGetTTPData = (step, forceRefresh = false) => {
		if (
			(forceRefresh || !this.props.partnersData.ladditionTapToPay) &&
			step === 'agree_cgv'
		) {
			this.props.getPartnerData('ladditionTapToPay');
		}
	};

	// Fonction vérifiant si il y a du contenu extensible à afficher
	checkExpand = () => {
		this.openCloseExpand(false);
		if (this.props.config.steps) {
			for (let step of this.props.config.steps) {
				if (step.step === this.props.config.state && this.state.configLaunch) {
					this.openCloseExpand(true);
				}
			}
		}
	};

	// Fonction permettant d'afficher le blur background et d'avoir la desactivation au clic de la souris en dehors de la card
	launchConfig = () => {
		this.props.setBlurBackground(true, this.wrapperRef);
		this.loadPartnerData();
		document.addEventListener('mousedown', this.handleClickOutside);
		if (!this.state.configLaunch) {
			this.setState({
				configLaunch: true,
			});
		}
	};

	// Tout est dans le nom de la fonction, on met le wrapper en reference pour le blur
	setWrapperRef(node) {
		this.wrapperRef = node;
	}

	// permet de "terminer" une configuration alors qu'elle est en cours, en cliquant en dehors de la card
	handleClickOutside(event) {
		if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			document.removeEventListener('mousedown', this.handleClickOutside);
			this.props.setBlurBackground(false);
			this.setState({
				configLaunch: false,
			});
		}
	}

	// Ouvre et ferme le panel extensible
	openCloseExpand = isOpen => {
		if (isOpen) {
			scroller.scrollTo('scrollToExpand', {
				duration: 800,
				delay: 0,
				smooth: 'easeInOutQuad',
				offset: -50,
			});
		}
		this.setState({
			open: isOpen,
		});
	};

	loadPartnerData = _ => {
		switch (this.props.config.prefix) {
			case 'mewsSystems':
				this.handleGetMewsData(this.props.config.state);
				break;
			case 'tickncook':
				this.handleGetTickncookData(this.props.config.state);
				break;
			case 'ladditionTapToPay':
				this.handleGetTTPData(this.props.config.state);
				break;
			default:
				break;
		}
	};

	onChange = (entity, value) => {
		this.setState({ [entity]: value, inputFilled: value.trim('') !== '' });
	};

	handleChange = panel => {
		this.setState({
			expanded: panel,
		});
	};

	getUrlAUthUberEatsLogin = () => {
		this.setState({ redirectWait: true });
		let urlLogin = 'https://login.uber.com/oauth/v2/authorize';
		let params = '?response_type=code&scope=eats.pos_provisioning';
		const client_id = this.props.user.ubereatsClientId;
		params += `&client_id=${client_id}`;

		const medUrl = window.location.origin;

		const redirect_uri = medUrl + '/redirect/partners/ubereats';
		params += `&redirect_uri=${redirect_uri}`;
		this.props.addNotif({
			key: new Date().getTime() + Math.random(),
			message: `${I18n.t('partner.ubereats_redirection')}...`,
			options: {
				variant: 'info',
				autoHideDuration: 10000,
			},
		});

		setTimeout(() => {
			window.location.href = `${urlLogin}${params}`;
		}, 2500);
	};

	selectStore = uuid => {
		this.setState({ uuid }, () => {
			this.sendNewState();
		});
	};

	deliverectProspectClick = _ => {
		this.props.handlePartnerProspect('deliverect');
	};

	/////////////////////////
	/////////////////////////
	/// Contenu des cards ///
	/////////////////////////
	/////////////////////////

	// Le contenu du header de la card
	headerContent = step => {
		return (
			<div className="step-header">
				<h3>
					{I18n.t('partner.step.step')} {step}
					<PartnerContact
						onOpen={this.removeListener}
						onClose={this.addListener}
						type="card"
						prefix={this.props.config.prefix}
					/>
				</h3>

				{this.props.config.logo ? (
					<img src={this.props.config.logo} alt={this.props.config.prefix} />
				) : (
					<p className="partnerconfig-partner-title">
						{this.props.config.prefix}
					</p>
				)}
			</div>
		);
	};

	documentationContent = () => {
		const partner_name = I18n.t('partner.prefix.' + this.props.config.prefix);
		let partner_url_key = '';
		let text = '';
		switch (this.props.config.prefix) {
			case 'eviivo':
				partner_url_key = 'pms';
				break;
			case 'ladditionTapToPay':
				text = I18n.t('partner.tap_to_pay.documentation');
				partner_url_key = this.props.config.prefix;
				break;
			default:
				partner_url_key = this.props.config.prefix;
				text = I18n.t('partner.documentation', { partner: partner_name });
		}
		return (
			<div className="content-card-3">
				<p className="content-card-p">{text}</p>
				<a
					href={I18n.t(`partner.integration_guide_pdf.${partner_url_key}`)}
					download
					target="_blank"
					rel="noopener noreferrer"
					onClick={() => this.setState({ doc_readed: true })}>
					<Button className="input-button">
						{I18n.t('partner.download_guide')}
					</Button>
				</a>
			</div>
		);
	};

	linkIpadCheckContent = () => {
		return (
			<div className="content-card-3">
				<p className="content-card-p">
					{I18n.t('partner.check_connexion_with_partner')}
				</p>
				<Button className="input-button" onClick={this.sendNewState}>
					{I18n.t('partner.check_connexion')}
				</Button>
			</div>
		);
	};

	ttpOnboardingContent = code => {
		return (
			<div className="content-card-3">
				<p className="content-card-p">
					{I18n.t('partner.check_existing_acount')}
				</p>
				<div style={{ textAlign: 'center' }}>
					<a
						className="red-button"
						href={`${getPayplusUrl()}/onboarding/${code}`}
						target="_blank"
						onClick={() => {
							this.setState({ linkClicked: true });
						}}>
						<button style={{ border: 'none' }}>
							{I18n.t('partner.check_existing_acount_link')}
						</button>
					</a>
				</div>
			</div>
		);
	};

	checkAccessAdminMenuContent = () => {
		if (this.props.access_admin_menu) {
			return this.linkIpadCheckContent();
		} else {
			return (
				<div className="content-card-3">
					<p className="content-card-p">
						{I18n.t('partner.ladditionMenu_accessAdminMenu_missing')}
					</p>
				</div>
			);
		}
	};

	setPaiementContent = () => {
		return (
			<div className="content-card-3">
				<p className="content-card-p">
					{this.props.config.prefix === 'ladditionTicket'
						? I18n.t('partner.choose_paiement_ticket')
						: I18n.t('partner.choose_paiement')}
				</p>
				<PaymentType
					selected={this.state.selectedPaymentType || ''}
					list={this.props.paymentTypes}
					onChange={this.onChange}
					removeListener={this.removeListener}
					addListener={this.addListener}
					required={true}
				/>
			</div>
		);
	};

	setRoomContent = () => {
		return (
			<div className="content-card-3">
				<p className="content-card-p">{I18n.t('partner.choose_room')}</p>
				<Table
					selected={this.state.selectedTable || ''}
					list={this.props.floors}
					onChange={this.onChange}
					removeListener={this.removeListener}
					addListener={this.addListener}
					required={true}
				/>
			</div>
		);
	};

	setPriceCategoryContent = () => {
		return (
			<div className="content-card-3">
				<p className="content-card-p">
					{I18n.t('partner.choose_priceCategory')}
				</p>
				<PriceCategory
					selected={this.state.selectedPriceCategory || ''}
					list={this.props.priceCategories}
					onChange={this.onChange}
					removeListener={this.removeListener}
					addListener={this.addListener}
				/>
			</div>
		);
	};

	linkIpadContent = () => {
		return (
			<div className="content-card-3">
				<p className="content-card-p">{I18n.t('partner.active_link_ipad')}</p>
			</div>
		);
	};

	checkOverpaidContent = () => {
		return (
			<div className="content-card-3">
				<p className="content-card-p">{I18n.t('partner.check_overpaid')}</p>
			</div>
		);
	};
	updateNucContent = () => {
		return (
			<div className="content-card-3">
				<p className="content-card-p">{I18n.t('partner.update_nuc')}</p>
			</div>
		);
	};

	// Contenu de la card 1, permettant de spécifier l'uuid du client
	authUberEatsContent = () => {
		return (
			<div className="uuid-container complex-content">
				<Button
					disabled={this.state.redirectWait}
					className="input-button"
					onClick={this.getUrlAUthUberEatsLogin}>
					{I18n.t('partner.ubereats.label_login')}
				</Button>
				<a
					className={'link-ubereats-signup'}
					href={I18n.t('partner.ubereats.url_signup')}
					target="_blank">
					{I18n.t('partner.ubereats.label_signup')}
				</a>
			</div>
		);
	};

	storesUberEatsContent = () => {
		return (
			<div className="content-card-3">
				{this.props.config.stores && this.props.config.stores.length ? (
					<>
						<p className="content-card-p">{I18n.t('partner.select_store')}</p>
						{this.props.config.stores.map(store => (
							<div className={'content-card-store'}>
								{' '}
								<p className="content-card-p store-ubereats">
									{store.name} <span>{store.store_id}</span>
								</p>
								<Button onClick={() => this.selectStore(store.store_id)}>
									{I18n.t('general.confirm')}
								</Button>
							</div>
						))}
					</>
				) : (
					<p className="content-card-p">
						{I18n.t('partner.ubereats_no_store')}
					</p>
				)}
			</div>
		);
	};

	mewsDataContent = () => {
		const entity = MEWS_STEP_TO_ENTITY[this.props.config.state];

		let list =
			(this.props.partnersData.mewsSystems &&
				this.props.partnersData.mewsSystems[entity]) ||
			[];
		let inputDescription = '';
		let inputLabel = '';
		let labelError = '';
		switch (this.props.config.state) {
			case 'set_service_id':
				inputDescription = I18n.t(
					'partner.mewsSystems.serviceInputDescription'
				);
				inputLabel = I18n.t('partner.mewsSystems.serviceInputLabel');
				labelError = I18n.t('partner.mewsSystems.servicesEmpty');
				break;
			case 'set_outlet_id':
				inputDescription = I18n.t('partner.mewsSystems.outletInputDescription');
				inputLabel = I18n.t('partner.mewsSystems.outletInputLabel');
				labelError = I18n.t('partner.mewsSystems.outletEmpty');
				break;
			case 'set_default_payment_id':
				inputDescription = I18n.t(
					'partner.mewsSystems.paymentInputDescription'
				);
				inputLabel = I18n.t('partner.mewsSystems.paymentInputLabel');
				labelError = I18n.t('partner.mewsSystems.paymentEmpty');
				list = list.filter(i => i.Classification === 'Payments');
				break;
			default:
				break;
		}

		if (list.length) {
			return (
				<div className="content-card-3">
					<p className="content-card-p">{inputDescription}</p>
					<div className="container-mews-select">
						<FormControl>
							<InputLabel id="select-mews-data-label">{inputLabel}</InputLabel>
							<Select
								className="select-mews-data"
								labelId="select-mews-data-label"
								selected={this.state.selectedMewsData || ''}
								onChange={e =>
									this.setState({ selectedMewsData: e.target.value })
								}
								onOpen={this.removeListener}
								onClose={this.addListener}>
								{list.map((item, index) => (
									<MenuItem key={index} value={item.Id}>
										{item.Name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</div>
				</div>
			);
		} else {
			return (
				<div className="content-card-3">
					<p className="content-card-p">{labelError}</p>
					<Button
						onClick={() =>
							this.handleGetMewsData(this.props.config.state, true)
						}>
						{' '}
						{I18n.t('partner.mewsSystems.refreshData')}
					</Button>
				</div>
			);
		}
	};

	tickncookKitchenIdContent = _ => {
		if (
			this.props.partnersData['tickncook'] &&
			this.props.partnersData['tickncook'].length
		) {
			return (
				<div className="content-card-3">
					<p className="content-card-p">
						{I18n.t('partner.tickncook.select_kitchen')}
					</p>
					<div className="container-mews-select">
						<FormControl>
							<InputLabel id="select-mews-data-label">
								{I18n.t('partner.tickncook.kitchen')}
							</InputLabel>
							<Select
								className="select-mews-data"
								labelId="select-mews-data-label"
								selected={this.state.customer_id || ''}
								onChange={e => this.onChange('customer_id', e.target.value)}
								onOpen={this.removeListener}
								onClose={this.addListener}>
								{this.props.partnersData['tickncook'].map((item, index) => (
									<MenuItem key={index} value={item._id}>
										{item.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</div>
				</div>
			);
		} else {
			return this.defaultContent();
		}
	};

	brandIdContent = _ => {
		const label =
			this.props.config.prefix === 'deliveroo'
				? I18n.t('partner.deliveroo_laddition_id')
				: I18n.t('partner.rushour_laddition_id');
		return (
			<div className="content-card-3">
				<p className="content-card-p">{label} :</p>
				<p className="content-card-p deliveroo-id">
					<span>{this.props.user.id}</span>
				</p>
			</div>
		);
	};
	downloadAppTTPContent = _ => {
		return (
			<div className="content-card-3">
				<p className="content-card-p">
					<p className="content-card-p">
						{I18n.t('partner.tap_to_pay.success')} :
					</p>
				</p>
			</div>
		);
	};

	cgvContent = () => {
		if (this.props.partnersData.ladditionTapToPay) {
			if (
				this.props.partnersData.ladditionTapToPay &&
				this.props.partnersData.ladditionTapToPay.length
			) {
				return (
					<div className="content-card-3">
						<p className="content-card-p">
							{I18n.t('partner.tap_to_pay.please_agree_cgv')}
						</p>
						<Button onClick={() => this.displayCGVTapToPay()}>
							{I18n.t('partner.tap_to_pay.display_cgv')}
						</Button>
					</div>
				);
			} else {
				// rien a signer
				return (
					<div className="content-card-3">
						<p className="content-card-p">
							{I18n.t('partner.tap_to_pay.cgv_allready_sign')}
						</p>
						<Button onClick={this.sendNewState}>
							{I18n.t('partner.tap_to_pay.continue_cgv')}
						</Button>
					</div>
				);
			}
		} else {
			return (
				<div className="content-card-3">
					<p className="content-card-p">
						{I18n.t('partner.tap_to_pay.data_empty')}
					</p>
					<Button
						onClick={() =>
							this.handleGetTTPData(this.props.config.state, true)
						}>
						{' '}
						{I18n.t('partner.tap_to_pay.refresh_data')}
					</Button>
				</div>
			);
		}
	};

	displayCGVTapToPay = () => {
		this.removeListener();
		const url = this.props.partnersData.ladditionTapToPay[0].url;
		const toDisplay = (
			<object data={url} type="application/pdf" width="100%" height="500px">
				<p>
					Affichage CGU non disponible. <a href={url}>Télécharger</a> instead.
				</p>
			</object>
		);
		confirm(toDisplay, {
			title: I18n.t('partner.tap_to_pay.cgv_popup_title'),
			mustConfirm: true,
			mustConfirmLabel: I18n.t('partner.tap_to_pay.cgv_checkbox'),
		}).then(
			() => {
				this.addListener();
				this.sendNewState();
			},
			() => {
				this.addListener();
			}
		);
	};

	ttpInitAccountContent = () => {
		return (
			<div className="content-card-3">
				<p className="content-card-p">
					{I18n.t('partner.tap_to_pay.init_account_label')}
				</p>
			</div>
		);
	};

	slideshow = slides => {
		let slidesJSX = [];
		const images = require.context('../../../../img', true);
		for (let slide of slides) {
			slidesJSX.push(
				<div className="each-fade">
					<div className="image-container">
						{slide.indexOf('http') !== -1 ? (
							<img src={slide} />
						) : (
							<img src={images(`./${slide}.png`)} />
						)}
					</div>
				</div>
			);
		}
		return (
			<div className="slide-container">
				<Fade {...properties}>{slidesJSX}</Fade>
			</div>
		);
	};

	contentInitLink = () => {
		return (
			<div className="content-card-3  content-card-como">
				<p className="content-card-p">{I18n.t('partner.init_liaison')}</p>
				{this.buttonToConfigure()}
			</div>
		);
	};

	contentAccountId = () => {
		return (
			<>
				{this.setInputContent(
					'deliverect_account_id',
					'',
					<p
						className={'link-ubereats-signup'}
						onClick={this.deliverectProspectClick}>
						{I18n.t('partner.label_lead_partner', {
							partner: I18n.t('partner.prefix.' + this.props.config.prefix),
						})}
					</p>
				)}
			</>
		);
	};
	setInputContent = (key, placeholder, child, childPosition = 'after') => {
		return (
			<div className="content-card-3 content-card-como">
				{(childPosition === 'before' && child) || ''}
				<p className="content-card-p ">{this.getInputLabel(key)}</p>
				<TextField
					onChange={e => this.onChange(key, e.target.value)}
					value={this.state[key] || ''}
					variant={'outlined'}
					size="small"
					placeholder={placeholder || ''}
				/>
				{(childPosition === 'after' && child) || ''}
			</div>
		);
	};

	getInputLabel = key => {
		let label = '';

		switch (key) {
			case 'deliverect_location_id':
				label = I18n.t('partner.deliverect_input_location_id');
				break;
			case 'deliverect_account_id':
				label = I18n.t('partner.deliverect_input_account_id');
				break;
			case 'external_id':
				label = I18n.t('partner.como_input_pos_external_id');
				break;
			case 'joining_item':
				label = I18n.t('partner.como_input_joining_item');
				break;
			case 'api_key':
				label = I18n.t('partner.como_input_api_key');
				break;
			case 'customer_id':
				switch (this.props.config.prefix) {
					case 'misterBooking':
						label = I18n.t('partner.misterbooking_customer_id');
						break;
					case 'eviivo':
						label = I18n.t('partner.eviivo_customer_id');
						break;
					case 'mewsSystems':
						label = I18n.t('partner.mews_customer_id');
						break;
					case 'dood':
						label = I18n.t('partner.dood_customer_id');
						break;
					case 'tickncook':
						label = label = I18n.t('partner.tickncook_customer_id');
						break;
					case 'zerosix':
						label = label = I18n.t('partner.zerosix_customer_id');
						break;
					case 'deliveroo':
						label = label = I18n.t('partner.deliveroo_brand_id');
						break;
					case 'dishop':
						label = label = I18n.t('partner.dishop_customer_id');
						break;
					default:
						label = I18n.t('partner.input_set_customer_id');
				}

				break;
			case 'token_tabesto':
				label = I18n.t('partner.token_tabesto');
				break;
			case 'token_koust':
				label = I18n.t('partner.token_koust');
				break;
			case 'token_barratio':
				label = I18n.t('partner.token_barratio');
				break;

			default:
				break;
		}
		return label;
	};

	// Contenu de la card, fonction parente
	content = state => {
		let stepLabel = this.props.steps[state];
		switch (state) {
			case 'set_account_id':
				return (
					<div>
						{this.headerContent(stepLabel)}
						{this.contentAccountId()}
					</div>
				);
			case 'set_location_id':
				return (
					<div>
						{this.headerContent(stepLabel)}
						{this.setInputContent('deliverect_location_id')}
					</div>
				);

			case 'set_room':
				return (
					<div>
						{this.headerContent(stepLabel)}
						{this.setRoomContent()}
					</div>
				);
			case 'set_paiement':
				return (
					<div>
						{this.headerContent(stepLabel)}
						{this.setPaiementContent()}
					</div>
				);
			case 'set_price_category':
				return (
					<div>
						{this.headerContent(stepLabel)}
						{this.setPriceCategoryContent()}
					</div>
				);
			case 'set_partner_and_discount_id':
				return (
					<div>
						{this.headerContent(1)}
						{this.contentInitLink()}
					</div>
				);

			case 'set_joining_item':
				return (
					<div>
						{this.headerContent(2)}
						{this.setInputContent('joining_item')}
					</div>
				);
			case 'set_api_key':
				return (
					<div>
						{this.headerContent(3)}
						{this.setInputContent('api_key')}
					</div>
				);
			case 'set_customer_id':
			case 'set_tickncook_account_id':
				return (
					<div>
						{this.headerContent(1)}
						{this.setInputContent('customer_id')}
					</div>
				);
			case 'set_token_tabesto':
				return (
					<div>
						{this.headerContent(1)}
						{this.setInputContent('token_tabesto')}
					</div>
				);

			case 'set_token_koust':
				return (
					<div>
						{this.headerContent(1)}
						{this.setInputContent('token_koust')}
					</div>
				);
			case 'set_token_barratio':
				return (
					<div>
						{this.headerContent(1)}
						{this.setInputContent('token_barratio')}
					</div>
				);
			case 'set_branch_pos_id':
			case 'set_liaison_como':
			case 'set_liaison_webview_loyalty':
			case 'set_auth_webview_loyalty_link':
			case 'set_api_mask':
			case 'set_api_discount':
			case 'set_api_payment_type':
			case 'set_variable_config_channel':
			case 'set_partner_options':
			case 'link_ipad_check':
			case 'set_link_deliverect':
			case 'set_table_and_paiement':
			case 'call_laddition_ticket':
			case 'set_api_variables':
			case 'set_link_reconciliation':
			case 'set_link_laddition_print':
			case 'set_table_payment_and_price_category':
			case 'set_entities':
			case 'set_send_feature':
			case 'set_stock_online':
			case 'call_inform_partner':
			case 'init_partner':
			case 'set_ipad_token':
			case 'set_api_data':
			case 'init_api_data':
			case 'check_liveness':
			case 'set_auth_group':
			case 'link_external_data':
			case 'trigger_synchro':
			case 'unlink_partners':
			case 'trigger_menu_synchro':
			case 'generate_export':
			case 'init_client_payplus_account':
			case 'set_api_get_timeout':
				return (
					<div>
						{this.headerContent(stepLabel)}
						{this.linkIpadCheckContent()}
					</div>
				);
			case 'set_auth_link':
				switch (this.props.config.prefix) {
					case 'celladdition':
						return this.defaultContent();
					default:
						return (
							<div>
								{this.headerContent(stepLabel)}
								{this.linkIpadCheckContent()}
							</div>
						);
				}
			case 'check_access_admin_menu':
				return (
					<div>
						{this.headerContent(stepLabel)}
						{this.checkAccessAdminMenuContent()}
					</div>
				);
			case 'documentation':
				return (
					<div>
						{this.headerContent(stepLabel)}
						{this.documentationContent()}
					</div>
				);
			case 'check_overpaid':
				return (
					<div>
						{this.headerContent(stepLabel)}
						{this.checkOverpaidContent()}
					</div>
				);
			case 'update_nuc':
				return (
					<div>
						{this.headerContent(stepLabel)}
						{this.updateNucContent()}
					</div>
				);
			case 'uuid_at_client':
				if (this.props.config.stores) {
					return (
						<div>
							{this.headerContent(1)}
							{this.storesUberEatsContent()}
						</div>
					);
				} else {
					return (
						<div>
							{this.headerContent(1)}
							{this.authUberEatsContent()}
						</div>
					);
				}
			case 'set_tickncook_kitchen_id':
				return (
					<div>
						{this.headerContent(stepLabel)}
						{this.tickncookKitchenIdContent()}
					</div>
				);
			case 'set_service_id':
			case 'set_outlet_id':
			case 'set_default_payment_id':
				return (
					<div>
						{this.headerContent(stepLabel)}
						{this.mewsDataContent()}
					</div>
				);
			case 'set_brand_id':
			case 'set_restaurant_id':
				return (
					<div>
						{this.headerContent(stepLabel)}
						{this.brandIdContent()}
					</div>
				);
			case 'download_app':
				return (
					<div>
						{this.headerContent(stepLabel)}
						{this.downloadAppTTPContent()}
					</div>
				);
			case 'agree_cgv':
				return (
					<div>
						{this.headerContent(stepLabel)}
						{this.cgvContent()}
					</div>
				);

			case 'check_existing_acount':
				const payplus_ttp_code = getVariableServer(
					this.props.variables,
					'payplus_ttp_code'
				);
				return (
					<div>
						{this.headerContent(stepLabel)}
						{payplus_ttp_code
							? this.ttpOnboardingContent(payplus_ttp_code)
							: this.linkIpadCheckContent()}
					</div>
				);

			case 'initial':
			default:
				return this.defaultContent();
		}
	};

	defaultContent = () => {
		return (
			<>
				<span className="princing-info">{this.props.config.pricingInfo}</span>
				{this.props.config.logo ? (
					<div className="cardConfig-logo">
						<img src={this.props.config.logo} alt={this.props.config.prefix} />
					</div>
				) : (
					<p className="partnerconfig-partner-title">
						{this.props.config.prefix}
					</p>
				)}
			</>
		);
	};

	// Contenu de la modal expensive
	contentExpand = (stepp, position, externe_link, dynamic = false) => {
		let description = [];
		let actives = [];
		let suspends = [];
		if (dynamic) {
			if (stepp.description) {
				actives = stepp.description['actives'].map((desc, index) => {
					return (
						<p key={index}>
							•{' '}
							<ReactMarkdown
								children={desc}
								rehypePlugins={[rehypeRaw, remarkGfm]}
							/>
						</p>
					);
				});
				suspends = stepp.description['suspends'].map((desc, index) => {
					return (
						<p key={index}>
							•{' '}
							<ReactMarkdown
								children={desc}
								rehypePlugins={[rehypeRaw, remarkGfm]}
							/>
						</p>
					);
				});
			}
		} else {
			if (JSON.parse(stepp.description))
				if (JSON.parse(stepp.description).length > 0) {
					description = JSON.parse(stepp.description);
					description = description.map((desc, i) => {
						return (
							<p key={`desc-${i}`}>
								<ReactMarkdown
									children={desc}
									rehypePlugins={[rehypeRaw, remarkGfm]}
								/>
							</p>
						);
					});
				}
		}
		let classNameExpand = '';
		switch (position) {
			case 1:
				classNameExpand = externe_link
					? 'expand-content after-center-link'
					: 'expand-content after-center';
				break;
			case 2:
				classNameExpand = externe_link
					? 'expand-content after-right-link'
					: 'expand-content after-right';
				break;
			default:
				classNameExpand = externe_link
					? 'expand-content after-left-link'
					: 'expand-content after-left';
				break;
		}
		const images = require.context('../../../../img', true);
		return (
			<div className={classNameExpand}>
				{externe_link && externe_link.value ? (
					<div className="expand-external-content">
						{externe_link.key_ === 'image' ? (
							externe_link.value.indexOf('http') !== -1 ? (
								<img src={externe_link.value} alt={externe_link.key_} />
							) : (
								<img src={images(`./${externe_link.value}.png`)} />
							)
						) : externe_link.key_ === 'video_youtube' ? (
							<iframe src={externe_link.value} />
						) : externe_link.key_ === 'slideshow' ? (
							this.slideshow(externe_link.value)
						) : externe_link.key_ === 'video' ? (
							<Media>
								<div className="media">
									<div className="media-player">
										<Player
											ref={c => (this._mediaPlayer = c)}
											src={externe_link.value}
											mozallowfullscreen=""
											webkitallowfullscreen=""
											allowfullscreen=""
										/>
									</div>
								</div>
							</Media>
						) : null}
					</div>
				) : null}
				<div className="expand-external-description">
					<h4>{stepp.title}</h4>
					{dynamic ? (
						stepp.description ? (
							<div>
								<div className="missing-id-content">
									<Accordion
										square
										expanded={this.state.expanded === 'panel1'}
										onChange={() => this.handleChange('panel1')}>
										<AccordionSummary
											aria-controls="panel1d-content"
											id="panel1d-header"
											expandIcon={<ExpandMoreIcon />}>
											<h5>{I18n.t('partner.actives_product')}</h5>
										</AccordionSummary>
										<AccordionDetails>
											<div className="actives_suspends_products">
												{actives ? actives : null}
											</div>
										</AccordionDetails>
									</Accordion>
									<Accordion
										square
										expanded={this.state.expanded === 'panel2'}
										onChange={() => this.handleChange('panel2')}>
										<AccordionSummary
											aria-controls="panel2d-content"
											id="panel2d-header"
											expandIcon={<ExpandMoreIcon />}>
											<h5>{I18n.t('partner.suspends_product')}</h5>
										</AccordionSummary>
										<AccordionDetails>
											<div className="actives_suspends_products">
												{suspends ? suspends : null}
											</div>
										</AccordionDetails>
									</Accordion>
								</div>
								<p className="incomplete_text_expand">
									{I18n.t('partner.check_product_incomplete')}
								</p>
								<a
									href="https://admin.laddition.com/document/Uber_Eats_Guide_dintegration.pdf"
									download
									target="_blank">
									<Button className="input-button">
										{I18n.t('partner.download_guide')}
									</Button>
								</a>
							</div>
						) : (
							<div>
								<p className="incomplete_text_expand">
									{I18n.t('partner.check_product_empty')}
								</p>
								<a
									href="https://admin.laddition.com/document/Uber_Eats_Guide_dintegration.pdf"
									download
									target="_blank">
									<Button className="input-button">
										{I18n.t('partner.download_guide')}
									</Button>
								</a>
							</div>
						)
					) : description ? (
						description
					) : null}
				</div>
			</div>
		);
	};

	// Parent du contenu de la modal expensive
	expandContainer = (stepp, dynamic = false) => {
		let externe_link = '';
		let finalStep = '';

		if (dynamic) {
			externe_link = stepp.externe_link;
			finalStep = stepp;
		} else {
			if (this.props.config.steps) {
				for (let step of this.props.config.steps) {
					if (step.step === this.props.config.state) {
						externe_link = JSON.parse(step.externe_link);
						finalStep = step;
					}
				}
			}
		}
		if (finalStep) {
			const position = this.props.index % 3;
			let classNameExpand = '';
			switch (position) {
				case 1:
					classNameExpand = externe_link
						? 'expand-center-link'
						: 'expand-center';
					break;
				case 2:
					classNameExpand = externe_link ? 'expand-right-link' : 'expand-right';
					break;
				default:
					classNameExpand = externe_link ? 'expand-left-link' : 'expand-left';
					break;
			}
			return (
				<Expand open={this.state.open} className={classNameExpand}>
					{this.contentExpand(
						finalStep,
						position,
						externe_link,
						dynamic ? true : false
					)}
				</Expand>
			);
		}
	};

	// Affichage du bouton en bas à droite de la card
	nextStepButtonContent = text => {
		return (
			<p className="configuration-button-next" onClick={this.continueConfigure}>
				{text || I18n.t('partner.step.next_step')}
			</p>
		);
	};

	// Affichage du bouton en bas à droite de la card en fonction de l'étape
	nextStepButton = state => {
		switch (state) {
			case 'initial':
				return (
					<p
						className="configuration-button-next"
						onClick={
							this.props.config.state === 'initial'
								? this.continueConfigure
								: this.launchConfig
						}>
						{this.props.config.state === 'initial'
							? this.props.config.max_step == 1
								? I18n.t('partner.one_click_activate')
								: I18n.t('partner.begin_configuration')
							: I18n.t('partner.continue_configuration')}
					</p>
				);

			case 'link_partner_to_customer':
				return (
					<p className="configuration-button-next disabled">
						{I18n.t('partner.step.next_step')}
					</p>
				);
			case 'link_partner_to_customer_ok':
			case 'set_price_category':
			case 'link_ipad':
			case 'update_nuc':
			case 'check_overpaid':
			case 'documentation':
			case 'set_brand_id':
			case 'set_restaurant_id':
			case 'generate_customer_id':
			case 'init_client_payplus_account':
				return this.nextStepButtonContent();
			case 'download_app':
				return this.nextStepButtonContent(I18n.t('partner.step.finish'));
			case 'set_joining_item':
			case 'set_api_key':
			case 'set_token_tabesto':
			case 'set_token_koust':
			case 'set_token_barratio':
			case 'set_customer_id':
			case 'set_account_id':
			case 'set_tickncook_account_id':
			case 'set_tickncook_kitchen_id':
				if (this.state.inputFilled) {
					return this.nextStepButtonContent();
				}
				break;

			case 'set_paiement':
				if (this.state.selectedPaymentType) {
					return this.nextStepButtonContent();
				}
				break;
			case 'set_room':
				if (this.state.selectedTable) {
					return this.nextStepButtonContent();
				}
				break;
			case 'set_location_id':
				if (this.state.inputFilled) {
					return this.nextStepButtonContent(
						I18n.t('partner.deliverect_check_link')
					);
				}
				break;

			case 'set_auth_link':
				switch (this.props.config.prefix) {
					case 'celladdition':
						return this.nextStepButtonContent();
					default:
						return <p className="configuration-button-next" />;
				}
			case 'set_service_id':
			case 'set_outlet_id':
			case 'set_default_payment_id':
				if (this.state.selectedMewsData) {
					return this.nextStepButtonContent();
				}
				break;
			case 'check_existing_acount':
				const payplus_ttp_code = getVariableServer(
					this.props.variables,
					'payplus_ttp_code'
				);
				if (payplus_ttp_code) {
					return this.nextStepButtonContent();
				}
				break;
			default:
				return <p className="configuration-button-next" />;
		}
	};

	// Envoi de la nouvelle étape en base
	sendNewState = () => {
		let data = {
			step: this.props.config.state,
			status: this.props.config.status,
			userId: this.props.user.id,
			partnerId: this.props.config.partner_id,
		};

		let steps = Object.keys(this.props.steps);
		const index = steps.findIndex(s => s === data.step);
		data.nextStep = steps[index + 1];
		switch (this.props.config.state) {
			case 'initial':
				data.prefix = this.props.config.prefix;
				break;
			case 'set_token_tabesto':
				data.token_tabesto = this.state.token_tabesto.trim();
				break;
			case 'set_token_koust':
				data.token_koust = this.state.token_koust.trim();
				break;
			case 'uuid_at_client_ok':
				data.step = 'link_partner_to_customer';
				this.props.putStep(data);
				break;
			case 'set_token_barratio':
				data.token_barratio = this.state.token_barratio.trim();
				break;
			case 'set_account_id':
				data.account_id = this.state.deliverect_account_id.trim();
				break;
			case 'set_location_id':
				data.location_id = this.state.deliverect_location_id.trim();
				break;
			case 'set_room':
				data.id_table = this.state.selectedTable.trim();
				break;
			case 'set_paiement':
				data.id_payment_type = this.state.selectedPaymentType;
				break;
			case 'set_price_category':
				data.id_price_category = this.state.selectedPriceCategory;
				break;
			case 'set_joining_item':
				data.joining_item = this.state.joining_item.trim();
				break;
			case 'set_api_key':
				data.api_key = this.state.api_key.trim();
				break;
			case 'set_customer_id':
			case 'set_tickncook_account_id':
			case 'set_tickncook_kitchen_id':
				data.customer_id = this.state.customer_id.trim();
				break;
			case 'uuid_at_client':
				data.uuid = this.state.uuid.trim();
				break;
			case 'set_service_id':
			case 'set_outlet_id':
			case 'set_default_payment_id':
				data.selected = this.state.selectedMewsData;
				this.setState({ selectedMewsData: '' });
				break;
			case 'agree_cgv':
				data.document = this.props.partnersData.ladditionTapToPay[0];
				break;
			default:
				break;
		}
		this.onChange('customer_id', '');
		this.props.putStep(data, this.props.config.prefix);
	};

	render() {
		return (
			<div
				className={
					this.state.configLaunch
						? 'expand-container z-index5'
						: 'expand-container'
				}
				ref={this.setWrapperRef}>
				<CardConfig
					key={this.props.key_}
					step={this.props.steps[this.props.config.state]}
					maxStep={this.props.config.max_step}
					configLaunch={this.state.configLaunch}
					nextStepButton={
						this.state.configLaunch
							? this.nextStepButton(this.props.config.state)
							: this.nextStepButton('initial')
					}>
					{this.state.configLaunch
						? this.content(this.props.config.state)
						: this.content('initial')}
				</CardConfig>
				{this.state.configLaunch
					? this.state.wrongCorrespondanceId
						? this.expandContainer(this.state.newStep, true)
						: this.expandContainer(this.props.steps[this.props.config.state])
					: this.expandContainer(0)}
			</div>
		);
	}
}

const mapStateToProps = (state, props) => {
	const config = getPartnerById(state, props);
	const user = state.service.user;

	return {
		config,
		user,
		paymentTypes: state.binding.paymentType,
		floors: state.binding.floor,
		priceCategories: state.binding.priceCategory,
		access_admin_menu: state.info.variable
			? state.info.variable.access_admin_menu === '1'
			: null,
		partnersData: state.partnersData,
		variables: state.variableServer,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		putStep: bindActionCreators(putPartnerStep, dispatch),
		putStatus: bindActionCreators(putPartnerStatus, dispatch),
		patchPaiementType: bindActionCreators(patchPartnerPaiementType, dispatch),
		patchFloor: bindActionCreators(patchPartnerFloor, dispatch),
		patchPriceCategory: bindActionCreators(patchPartnerPriceCategory, dispatch),
		addNewPartnerClient: bindActionCreators(postPartnerMedDefault, dispatch),
		generateTk: bindActionCreators(generateToken, dispatch),
		validationLinkPartnerToCustomer: bindActionCreators(
			validLinkPartnerToCustomer,
			dispatch
		),
		validationConnexionWithPartnerOk: bindActionCreators(
			validConnexionWithPartnerOk,
			dispatch
		),
		partnerUuidSelect: bindActionCreators(postPartnerChecking, dispatch),
		initializationPartner: bindActionCreators(
			postInitializationPartner,
			dispatch
		),
		checkMenu: bindActionCreators(getCheckMenu, dispatch),
		addNotif: bindActionCreators(enqueueSnackbar, dispatch),
		getPartnerData: prefix => dispatch(getData(prefix)),
		handlePartnerProspect: prefix =>
			dispatch({ type: 'HANDLE_PARTNER_PROSPECT', prefix }),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withSnackbar(ComplexPartner));
