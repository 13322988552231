import React, { Component } from 'react';
import { I18n } from 'react-redux-i18n';
import SectionList from './Component/SectionList';
import FormSection from './Component/FormSection';
import { connect } from 'react-redux';
import RecapExport from './Component/RecapExport';
import { PropTypes } from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import CalendarIcon from './img/calendar-big.svg';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, {
	formatDate,
	parseDate,
} from 'react-day-picker/moment';
import * as actions from '../../../../service/export/actions';

import {
	SET_FORM_VALUES,
	SET_SUPPLEMENT_FORM_VALUES,
} from '../../../../service/export/types';
import { getType, deepEqual } from '../../../../utils';
import { bindActionCreators } from 'redux';
import { deepClone } from '../../../../../../utils';
import { withSnackbar } from 'notistack';
import { FORM_PARTNERS } from './Component/FormProduct/Components/FormPartners/config';
import ExportName from '../../../ExportName';

class ExportModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedSection: '',
			selectedProduct: null,
			selectedDay: undefined,
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (
			nextProps.products &&
			prevState.selectedSection &&
			prevState.selectedProduct
		) {
			const product = ExportModal.formatProduct(
				nextProps.export,
				nextProps.products,
				nextProps.supplements,
				prevState.selectedSection,
				prevState.selectedProduct.id_product
			);

			if (product) {
				// if (product && !deepEqual(product, prevState.selectedProduct)) {
				return { selectedProduct: product };
			}
		}

		return null;
	}

	setSelected = (base, id) => {
		console.log({ base, id });
		if (base === 'section') {
			this.setState({
				selectedSection: id,
				selectedProduct: null,
			});
		} else if (base === 'product') {
			let product = ExportModal.formatProduct(
				this.props.export,
				this.props.products,
				this.props.supplements,
				this.state.selectedSection,
				id
			);

			if (product) {
				const pSelector = this.props.products[product.id_product];
				product = {
					...product,
					name: pSelector ? pSelector.name : '',
					rate: pSelector ? pSelector.rate : -1,
					rate_take_away: pSelector ? pSelector.rate_take_away : -1,
				};

				product.extras = {
					...product.extras,
					disabled:
						this.props.disabledProducts.includes(product.id_product) ||
						product.extras.disabled == 1 ||
						false,
				};

				this.setState({
					selectedProduct: product,
				});

				const product2 = deepClone(product);

				this.props.setFormValues(product2.extras);

				this.props.setSupplementFormValues(
					deepClone(product.category_supplement || [])
				);
			}
		}
	};

	static formatProduct(exp, products, supplements, idSection, idProduct) {
		const section = exp.sections.find(s => s.id === idSection);
		if (section) {
			let product = section.products.find(p => p.id_product === idProduct);

			if (product) {
				const pSelector = products[product.id_product];

				let productFormated = {
					...product,
					name: pSelector ? pSelector.name : '',
					rate: pSelector ? pSelector.rate : -1,
					rate_take_away: pSelector ? pSelector.rate_take_away : -1,
					catSuppExtraEmpty: !product.category_supplement,
				};

				if (
					!product.category_supplement &&
					pSelector &&
					pSelector.category_supplement &&
					pSelector.category_supplement.length
				) {
					let defaultSupp = pSelector.category_supplement.reduce(
						(acc, id_cat) => {
							if (supplements.hasOwnProperty(id_cat)) {
								acc.push(supplements[id_cat]);
							}
							return acc;
						},
						[]
					);
					productFormated.category_supplement = defaultSupp;
				}

				return productFormated;
			}
		}
		return null;
	}

	onClose = () => {
		this.setState(
			{
				selectedSection: '',
				selectedProduct: null,
				selectedDay: undefined,
			},
			() => {
				this.props.onClose();
			}
		);
	};

	onExport = () => {
		this.onClose();
		this.props.onExport();
	};

	handleDayChange = day => {
		const dateTime = +new Date(day);
		const timestamp = Math.floor(dateTime / 1000);
		this.setState({ selectedDay: timestamp }, () => {
			this.props.action.updateExport(
				this.props.idExport,
				this.state.exportName,
				this.state.selectedDay
			);
		});
	};

	render() {
		const { selectedSection, selectedProduct } = this.state;
		let date = this.props.export.date_available;
		let lang = window.navigator.language || 'fr';

		let middleContent = '';
		const attrs = {
			idExport: this.props.idExport,
			idSection: selectedSection,
			prefix: this.props.prefix,
		};
		if (selectedSection && selectedProduct) {
			if (FORM_PARTNERS.hasOwnProperty(this.props.prefix)) {
				let Form = FORM_PARTNERS[this.props.prefix];
				middleContent = (
					<Form
						{...attrs}
						product={selectedProduct}
						setSelected={this.setSelected}
						disableVisibility={this.props.disableVisibility}
					/>
				);
			} else {
				middleContent = '';
			}
		} else if (selectedSection) {
			middleContent = (
				<FormSection
					{...attrs}
					setSelected={this.setSelected}
					products={this.props.products}
					idMenu={this.props.id_menu}
				/>
			);
		} else {
			middleContent = (
				<p className="section-label no-section-selected">
					{I18n.t('exports.sectionList.select_section')}
				</p>
			);
		}

		const type = getType(this.props.prefix);

		return (
			<div className={'export-parent-container'}>
				<div className="exportModal-container">
					{/* <div className="export-name-calendar-container">
            <div className="text-content">
              <DayPickerInput
                formatDate={formatDate}
                parseDate={parseDate}
                format="LL"
                placeholder={
                  date
                    ? `${formatDate(new Date(date * 1000), "LL", lang)}`
                    : `${formatDate(new Date(), "LL", lang)}`
                }
                dayPickerProps={{
                  locale: lang,
                  localeUtils: MomentLocaleUtils,
                  modifiers: {
                    disabled: {
                      before: new Date()
                    }
                  }
                }}
                onDayChange={this.handleDayChange}
              />
              <img
                src={CalendarIcon}
                className="calendar"
                alt="calendar"
                className="export-modal-icon"
              />
            </div>
          </div> */}
					<CloseIcon
						onClick={this.onClose}
						className="export-modal-icon close-export-modal-icon"
					/>
				</div>

				<div className={'export-modal-container'}>
					<div className={'section-category'}>
						<SectionList
							idExport={this.props.idExport}
							setSelected={this.setSelected}
							selectedSection={parseInt(selectedSection)}
							selectedProduct={
								selectedProduct ? selectedProduct.id_product : ''
							}
							type={type}
							products={this.props.products}
						/>
					</div>
					<div className={'section-products'}>
						<h2>{I18n.t('exports.sectionList.details')}</h2>
						<div className="middle-content-container">{middleContent}</div>
					</div>
					<div className="recap-container">
						<h2>{I18n.t('exports.sectionList.recap')}</h2>
						<RecapExport
							idExport={this.props.idExport}
							type={type}
							products={this.props.products}
							prefix={this.props.prefix}
						/>
					</div>
				</div>
			</div>
		);
	}
}

ExportModal.propTypes = {
	openSectionsModal: PropTypes.bool.isRequired,
	idExport: PropTypes.number.isRequired,
	prefix: PropTypes.string.isRequired,
	onClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
	const exp = state.exports.list.find(e => e.id == props.idExport);
	let id_menu = findIdMenu(exp);
	if (!id_menu) {
		id_menu = state.exports.productsSelector.menus.selected;
	}
	const partnersStockActiveLive = state.exports.partnerStockActiveLive;
	return {
		export: exp ? exp : {},
		exportName: exp ? exp.name : '',
		id_menu,
		products: id_menu
			? state.exports.productsSelector.products.list[id_menu]
			: {},
		supplements: state.exports.supplement.list[id_menu] || {},
		disableVisibility:
			state.service.user.isSwift &&
			partnersStockActiveLive.includes(props.prefix), // supprimer si partnersStockActiveLive n'est pas utile, voir avec le temps (en gros si tous les partanaires peuvent gérer la modif du active)
		disabledProducts: state.exports.disabledProducts,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setFormValues: data => dispatch({ type: SET_FORM_VALUES, data }),
		setSupplementFormValues: data =>
			dispatch({ type: SET_SUPPLEMENT_FORM_VALUES, data }),
		action: bindActionCreators(actions, dispatch),
	};
};

const findIdMenu = exp => {
	let id_menu = false;
	if (exp && exp.sections && exp.sections.length) {
		for (let s of exp.sections) {
			if (s.products && s.products.length) {
				for (let p of s.products) {
					if (p.hasOwnProperty('id_menu')) {
						return p.id_menu;
					}
				}
			}
		}
	}
	return id_menu;
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withSnackbar(ExportModal));
