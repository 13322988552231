import React from 'react';
import { IconButton, Input } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import { I18n } from 'react-redux-i18n';
class InputEdit extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			value: null,
			edition: false,
		};
	}

	static getDerivedStateFromProps(newProps, prevState) {
		if (newProps.value !== prevState.value && !prevState.edition) {
			return { value: newProps.value };
		}
		return null;
	}

	onSave = e => {
		if (this.props.requiered && this.state.value.trim() == '') {
			alert(I18n.t('exports.field_cant_be_empty'));
			return;
		}
		this.setState({ edition: false });
		this.props.onChange(
			this.props.inputProps.type === 'number' && this.state.value < 0
				? 0
				: this.state.value
		);
	};

	onChange = e => {
		this.setState({ value: e.target.value });
	};

	openEdit = e => {
		e.stopPropagation();
		this.setState({ edition: true });
	};

	onKeyDown = e => {
		if (e.key === 'Enter') {
			this.onSave();
		}
	};

	render() {
		const displayOriginalValue =
			this.props.originalValue && this.props.originalValue != this.props.value;
		return (
			<div
				className={
					'input-supplement ' + this.props.className ? this.props.className : ''
				}>
				{!this.state.edition ? (
					<div>
						<p>
							<span className={this.props.classes}>
								{this.state.value}{' '}
								{displayOriginalValue ? (
									<i>({this.props.originalValue})</i>
								) : null}
							</span>
							{this.props.inputProps.type === 'number'
								? ' ' + this.props.sigle
								: ' '}
							<IconButton onClick={this.openEdit}>
								<EditIcon />
							</IconButton>
						</p>
					</div>
				) : (
					<div>
						<Input
							value={this.state.value}
							onChange={this.onChange}
							autoFocus
							inputProps={{ maxLength: 120, ...this.props.inputProps }}
							onKeyDown={this.onKeyDown}
						/>
						<IconButton onClick={this.onSave}>
							<SaveIcon />
						</IconButton>
					</div>
				)}
			</div>
		);
	}
}

export default InputEdit;
