import React, { Component } from 'react';
import './style.scss';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { getPartner } from '../../service/partners/selector';
import PartnerContainer from '../PartnerContainer';
import ExternalIdAssignation from '../../Components/ExternalDataAssignation';
import CancelNote from './Components/CancelNote';

const MB_TYPES = [
	'CB',
	'ESP',
	'CHQ',
	'TICKET_RESTAURANT',
	'AMEX',
	'VIR',
	'PERTE',
	'CHQVAC',
	'BON_CADEAU',
	'CHL',
	'AVOIR',
];

class MisterBooking extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		const { partner } = this.props;

		const MB_TYPE_MAPPED = MB_TYPES.map(e => ({
			id: e,
			name: I18n.t('partner.misterBooking_paymentType.' + e),
		}));

		return (
			<PartnerContainer
				name={I18n.t(`partner.prefix.${partner.prefix}`)}
				id={partner ? partner.id : null}
				prefix={partner ? partner.prefix : null}>
				<ExternalIdAssignation
					prefix={partner.prefix}
					type="paymentType"
					externals={MB_TYPE_MAPPED}
				/>
				<CancelNote />
			</PartnerContainer>
		);
	}
}

const mapStateToProps = (state, props) => {
	const partner = getPartner(state, props);
	return {
		partner,
	};
};

export default connect(mapStateToProps)(MisterBooking);
